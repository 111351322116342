import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const ProductPage = () => (
  <Layout pageInfo={{ pageName: "products" }}>
  <Seo title="TrackSecure Produkte" keywords={[ `Rennstrecke`, `Trackday`, `fahren`, `safety`, `Auto`, `Tracktool`, `Fahrsicherheit`, `Sicherheit`, `Mobilität`, `TrackSecure`, `App` ]} />
    <h1 class="heading-4">Produkte & Leistungen</h1>
    <p class="content-text">
      <ul>
        <li>TrackSecure Core</li>
        <li>TrackSecure for Community</li>
        <li>TrackSecure for TrackDay</li>
        <li>TrackSecure for Race</li>
      </ul>
    </p>

    <h2 id="core" class="heading-5">TrackSecure Core</h2>

    <p class="content-text">
      TBD: Allgemeine Beschreibung / kurzes Erklärvideo wie die TrackSecure Platform funktioniert.
      <br/>
      <br/>
      <br/>
      <br/>
    </p>

    <h2 id="community" class="heading-5">TS4Community</h2>

    <p class="content-text">
      TBD: Allgemeine Beschreibung / kurzes Erklärvideo wie die TrackSecure Platform funktioniert.
      <br/>
      <br/>
      <br/>
      <br/>
    </p>

    <h2 id="trackday" class="heading-5">TS4TrackDay</h2>

    <p class="content-text">
      TBD: Beschreibung der Features und Mehrwert für Trackday-Veranstalter.
      <br/>
      <br/>
      <br/>
      <br/>
    </p>

    <h2 id="race" class="heading-5">TS4Race</h2>

    <p class="content-text">
      TBD: Beschreibung der Features und Mehrwert für Rennveranstalter.
      <br/>
      <br/>
      <br/>
      <br/>
    </p>

  </Layout>
)

export default ProductPage
